<div class="schedule_form" [ngClass]="{'filter_calendar': !showFilters && _filters?.viewType == 'CALENDAR'}">
    <div class="request_filter_wr">
        <div class="flex items-center">
            <button (click)="showFilters = !showFilters" class="openFilterDtn relative" type="button" name="filterIsOpen">
                @if(filterCount){
                  <span class="bg-white text-blue-700 mr-2 px-2 rounded-full font-bold">{{ filterCount }}</span>
                }
                Filters
                <i class="fa-solid ml-2" [ngClass]="{
                  'fa-chevron-up': showFilters,
                  'fa-chevron-down': !showFilters
                }"></i>
                @if(filterCount > 0){
                  <button
                  type="button"
                  class="ml-3"
                  (click)="onClear.emit(); $event.stopPropagation()"
                  >
                    <i class="fa-solid fa-times"></i>
                  </button>
                }
            </button>
            <button *appHasPermission="'schedules_create'" (click)="onCreate.emit(); $event.stopPropagation()" class="create_btn ml-auto" type="button">
                Create
            </button>
        </div>
        @if (showFilters) {
            <div class="filter_inputs">
                <div class="mt-2 schedule_form_toggle">
                    <input type="checkbox" id="toggle" [checked]="_filters?.subType == 'Drivers'" class="toggleCheckbox" (click)="toggleScheduleType()"/>
                    <label for="toggle" class='toggleContainer'>
                    <div class="toggle-schedule">Trucks</div>
                    <div class="toggle-schedule">Drivers</div>
                    </label>
                </div>
                <div class="schedule_form_selects">
                    @if (_filters?.viewType == 'LIST') {
                        <div class="schedule_form_item">
                            @if (_filters?.subType == 'Drivers') {
                                @if (!_filters?.driver?.name) {
                                    <app-search
                                        [label]="'By Driver'"
                                        [resource]="'CONTACT'"
                                        [idInfo]="'by-driver-search'"
                                        [options]="{focus: true, createContact: true}"
                                        [createOptions]="{
                                          createLocalType: 'driver'
                                        }"
                                        [method]="'search'"
                                        [filter]="{type: 'DRIVER', accountId: currentUser?.company?._id, limit: 25, sort: 'quoteCount', dir: 'desc', active: true}"
                                        (onChange)="setFilter({
                                            driver: {
                                                _id: $event?._id,
                                                name: $event?.name
                                            }
                                        })"
                                    ></app-search>
                                } @else {
                                    <app-input
                                        [idInfo]="'by-driver'"
                                        [value]="_filters?.driver?.name"
                                        [display]="{ remove: true }"
                                        [isReadOnly]="true"
                                        (onRemove)="setFilter({
                                            driver: {
                                                _id: null,
                                                name: null
                                            }
                                        })"
                                    ></app-input>
                                }
                            } @else {
                                @if (!_filters?.unit?.name) {
                                    <app-search
                                    [idInfo]="'equipment-search'"
                                    [label]="'By Truck'"
                                    [resource]="'EQUIPMENT'"
                                    [options]="{focus: true}"
                                    [method]="'search'"
                                    [filter]="{carrierId: currentUser?.company?._id}"
                                    (onChange)="setFilter({
                                        unit: {
                                            _id: $event?._id,
                                            name: $event?.name
                                        }
                                    })"
                                ></app-search>
                                } @else {
                                    <app-input
                                        [idInfo]="'equipment-search'"
                                        [value]="_filters?.unit?.name"
                                        [display]="{ remove: true }"
                                        [isReadOnly]="true"
                                        (onRemove)="setFilter({
                                            unit: {
                                                _id: null,
                                                name: null
                                            }
                                        })"
                                    ></app-input>
                                }
                            }
                        </div>
                    }
                    <div class="schedule_form_item">
                        @if (!_filters?.dispatcher?.name) {
                            <app-search
                                [label]="'By Dispatch'"
                                [resource]="'CONTACT'"
                                [idInfo]="'dispatcher-search'"
                                [options]="{focus: true}"
                                [method]="'search'"
                                [filter]="{type: 'DISPATCH', accountId: currentUser?.company?._id, limit: 25, sort: 'quoteCount', dir: 'desc', active: true}"
                                (onChange)="setFilter({
                                    dispatcher: {
                                        _id: $event?._id,
                                        name: $event?.name
                                    }
                                })"
                            ></app-search>
                        } @else {
                            <app-input
                                [idInfo]="'by-dispatch'"
                                [value]="_filters?.dispatcher?.name"
                                [display]="{ remove: true }"
                                [isReadOnly]="true"
                                (onRemove)="setFilter({
                                    dispatcher: {
                                        _id: null,
                                        name: null,
                                    },
                                    allDispatcher: true
                                })"
                            ></app-input>
                        }
                    </div>
                        <div class="schedule_form_item">
                            <app-select
                                [idInfo]="'date-range'"
                                [keyName]="{key:'key', name:'name'}"
                                [list]="rangeTypes"
                                [value]="_filters?.rangeType"
                                [label]="'Date Range'"
                                [placeholder]="'Select Range'"
                                [options]="{ timezone: true }" 
                                (onSave)="selectDateRange($event)"
                            ></app-select>
                        </div>
                    @if (_filters?.viewType == 'LIST') {
                        <div class="schedule_form_item flex items-center">
                            <app-date 
                                [label]="'Start Date'" 
                                [startKey]="'startDate'" 
                                [parent]="_filters"
                                [options]="{ timezone: true }" 
                                (onSave)="setFilter({startDate: $event.startDate})"
                            ></app-date>
                            <div class="end_date">
                                <app-date 
                                    [label]="'End Date'" 
                                    [startKey]="'endDate'" 
                                    [parent]="_filters"
                                    [options]="{ timezone: true }" 
                                    (onSave)="setFilter({endDate: $event.endDate})"
                                ></app-date>
                            </div>
                        </div>
                    } @else {
                        <div class="schedule_form_item">
                            <app-date 
                                [label]="'Start Date'" 
                                [startKey]="'startDateCalendar'" 
                                [parent]="_filters"
                                [options]="{ timezone: true, notDelete: true }" 
                                (onSave)="setFilter({startDateCalendar: $event.startDateCalendar})"
                            ></app-date>
                        </div>
                        <div class="schedule_form_item">
                            <app-date 
                                [label]="'End Date'" 
                                [startKey]="'endDateCalendar'" 
                                [parent]="_filters"
                                [options]="{ timezone: true, notDelete: true }" 
                                (onSave)="setFilter({endDateCalendar: $event.endDateCalendar})"
                            ></app-date>
                        </div>
                    }
                </div>
                <button (click)="onClear.emit(); $event.stopPropagation()" class="reset_btn ml-auto" type="button" name="filterIsOpen">
                    Reset
                </button>
            </div>
        }
    </div>
    <div class="flex items-start">
        @if (_filters?.viewType == 'LIST') {
            <div class="schedule_tabs_wrapper">
                <div class="">
                    <app-filters
                        [status]="statuses"
                        [filters]="filtersWidthoutType"
                        [options]="{ upperCase: true }"
                        (filterSet)="setFilter({
                            status: $event.status,
                            page: 1,
                            type: null
                        })"
                    ></app-filters>
                </div>
                <div class="schedule_tabs_types">
                    <app-filters
                        [status]="types"
                        [filters]="filtersWidthoutStatus"
                        [property]="'type'"
                        [options]="{ upperCase: true }"
                        (filterSet)="setFilter({
                            type: $event.type,
                            page: 1
                        })"
                    ></app-filters>
                </div>
            </div>
        }
        <div class="schedule_tabs_right" [ngClass]="{'p-0': !showFilters && _filters?.viewType == 'CALENDAR'}">
            <div class="view_type">
                <button
                    (click)="setFilter({
                        viewType: 'LIST',
                        subType: 'Drivers'
                    })" [ngClass]="{'active_type': _filters?.viewType == 'LIST', 'type': _filters?.viewType !== 'LIST'}">
                    <i class="fa-solid fa-list"></i>
                </button>
                <button
                    (click)="setFilter({
                        viewType: 'CALENDAR',
                        subType: 'Drivers',
                        driver: {
                            _id: null,
                            name: null
                        },
                        unit: {
                            _id: null,
                             name: null
                        },
                        type: '',
                        status: ''
                    })" [ngClass]="{'active_type': _filters?.viewType == 'CALENDAR', 'type': _filters?.viewType !== 'CALENDAR'}">
                    <i class="fa-solid fa-calendar-day"></i>
                </button>
            </div>
        </div>
    </div>
</div>















