import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, } from '@angular/core'
import { Schedule } from '@app/shared'
import { Store } from '@ngrx/store'
import { combineLatest, Subject, takeUntil, tap } from 'rxjs'
import * as fromCoreStore from '@app/core/store'
import { OpenModal } from '@app/modals/store'
import * as fromUserStore from '@app/users/store'

@Component({
    selector: 'app-schedule-list',
    templateUrl: './schedule-list.component.html',
    styleUrls: ['./schedule-list.component.scss'],
    standalone: false
})
export class ScheduleListComponent implements OnInit, OnDestroy {
  @Input()
  schedules: Schedule[]
  @Input()
  filters
  @Input()
  total
  @Input()
  currentUser
  @Input()
  equipments
  @Output()
  onUpdate = new EventEmitter<any>()
  @Output()
  onPage = new EventEmitter<any>()
  limit = 25
  allDispatcher = false

  destroyed$ = new Subject<boolean>()

  constructor(private store: Store<fromCoreStore.State>) { }

  ngOnInit() {

    combineLatest([
      this.store.select(fromCoreStore.selectScheduleFilter),
      this.store.select(fromUserStore.getLoggedIn),
      this.store.select(fromUserStore.getUser),
    ])
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(([filters, loggedIn, user]) => {

        if (filters?.allDispatcher) {
          this.allDispatcher = true
        }

        if (filters?.type == 'DAY OFF') {
          filters['type'] = 'DAY_OFF'
        }

        if (!filters?.subType) {
          filters['subType'] = 'Drivers'
        }

        this.filters = filters

        let f = {
          ...filters
        }

        if (f?.rangeType) {
          delete f.rangeType
        }

        if (user?.type == 'DISPATCH' && !this.allDispatcher) {
          f['dispatcher'] = {
            _id: user._id,
            name: user.name
          }

          this.filters['dispatcher'] = {
            _id: user._id,
            name: user.name
          }
        }

        if (f?.driver) {
          f['driverId'] = f?.driver?._id
          delete f.driver
        }

        if (f?.unit) {
          f['unitId'] = f?.unit?._id
          delete f.unit
        }

        if (f?.dispatcher) {
          f['dispatchId'] = f?.dispatcher?._id
          delete f.dispatcher
        }
      })
  }

  openViewRequest(schedule) {
    this.store.dispatch(
      new OpenModal({
        type: 'VIEW_REQUEST',
        props: {
          windowClass: "view-request-modal",
          schedule,
          equipments: this.equipments,
          currentUser: this.currentUser,
          filters: this.filters
        },
      })
    )
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}



