import { Component, OnDestroy, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import moment from 'moment'

@Component({
    selector: 'app-schedule-filter',
    templateUrl: './schedule-filter.component.html',
    styleUrls: ['./schedule-filter.component.scss'],
    standalone: false
})
export class ScheduleFilterComponent implements OnInit, OnDestroy {
    @Input()
    set filters(v) {
        if (v) {
            this.filterCount = 0
            if (!v?.startDateCalendar) {
                v['startDateCalendar'] = moment().utc().startOf('month').format('MM/DD/YYYY')
            }

            if (!v?.endDateCalendar) {
                v['endDateCalendar'] = moment().utc().endOf('month').format('MM/DD/YYYY')
            }
            Object.keys(v).forEach((k) => {
                const isCalendarView = v['viewType'] === 'CALENDAR';

                if (
                    k !== 'viewType' &&
                    k !== 'subType' &&
                    k !== 'limit' &&
                    k !== 'page' &&
                    k !== 'rangeType' &&
                    k !== 'allDispatcher' &&
                    v[k] !== '' &&
                    v[k]?._id !== null &&
                    (isCalendarView || (k !== 'startDateCalendar' && k !== 'endDateCalendar')) &&
                    (!isCalendarView || (k !== 'startDate' && k !== 'endDate' && k !== 'driver' && k !== 'unit')) &&
                    k !== 'sort' && k !== 'dir'
                ) {
                    if (v[k]) {
                        this.filterCount++;
                    }
                }
            });

            this._filters = v
        }
    }

    defaultDate = null

    _filters

    @Input()
    drivers

    @Input()
    types
    @Input()
    statuses

    @Input()
    total

    @Input()
    filtersWidthoutStatus

    @Input()
    filtersWidthoutType
    showFilters = true
    filterCount = 0
    @Input()
    currentUser

    @Output()
    onUpdate = new EventEmitter<any>()

    @Output()
    onClear = new EventEmitter<any>()

    @Output()
    onCreate = new EventEmitter<any>()

    sortOptions = [
        { key: 'Driver Name', name: 'Driver Name' },
        { key: 'Status', name: 'Status' },
        { key: 'Truck', name: 'Truck' },
        { key: 'Request Date', name: 'Request Date' },
        { key: 'Dispatcher Requested', name: 'Dispatcher Requested' },
        { key: 'Trip Name', name: 'Trip Name' }
    ]

    rangeTypes = [
        { key: 'customDates', name: 'Custom dates' },
        { key: 'today', name: 'Today' },
        { key: 'thisWeek', name: 'This week' },
        { key: 'thisWeekToDate', name: 'This week to date' },
        { key: 'thisMonth', name: 'This month' },
        { key: 'thisMonthToDate', name: 'This month to date' },
        { key: 'thisQuarter', name: 'This quarter' },
        { key: 'thisQuarterToDate', name: 'This quarter to date' },
        { key: 'thisYear', name: 'This year' },
        { key: 'thisYearToDate', name: 'This year to date' },
        { key: 'thisYearToLastMonth', name: 'This year to last month' },
        { key: 'yesterday', name: 'Yesterday' },
        { key: 'lastWeek', name: 'Last week' },
        { key: 'lastWeekToDate', name: 'Last week to date' },
        { key: 'lastMonth', name: 'Last month' },
        { key: 'lastMonthToDate', name: 'Last month to date' },
        { key: 'lastQuarter', name: 'Last quarter' },
        { key: 'lastQuarterToDate', name: 'Last quarter to date' },
        { key: 'lastYear', name: 'Last year' },
        { key: 'lastYearToDate', name: 'Last year to date' },
        { key: 'last7Days', name: 'Last 7 days' },
        { key: 'last30days', name: 'Last 30 days' },
        { key: 'last90days', name: 'Last 90 days' },
    ]

    destroyed$ = new Subject<boolean>();

    constructor(
    ) { }

    ngOnInit(): void {
        this.defaultDate = {
            startDateCalendar: moment().startOf('month').format(),
            endDateCalendar: moment().endOf('month').format(),
        }
    }

    toggleScheduleType() {
        if (this._filters?.subType == 'Drivers') {
            this.onUpdate.emit({ subType: 'Trucks' })
        } else {
            this.onUpdate.emit({ subType: 'Drivers' })
        }
    }

    selectDateRange(event) {
        const currentDate = moment().format('L')

        const firstDayOfMonth = moment().startOf('month').format('L')
        const firstDayOfYear = moment().startOf('year').format('L')

        let rangeStartDate
        let rangeEndDate

        switch (event) {
            case 'customDates':
                rangeStartDate = ""
                rangeEndDate = ""
                break
            case 'today':
                rangeStartDate = currentDate
                rangeEndDate = currentDate
                break
            case 'thisWeek':
                rangeStartDate = moment().weekday(0).format('L')
                rangeEndDate = moment().weekday(6).format('L')
                break
            case 'thisWeekToDate':
                rangeStartDate = moment().weekday(0).format('L')
                rangeEndDate = currentDate
                break
            case 'thisMonth':
                rangeStartDate = firstDayOfMonth
                rangeEndDate = moment().endOf('month').format('L')
                break
            case 'thisMonthToDate':
                rangeStartDate = firstDayOfMonth
                rangeEndDate = currentDate
                break
            case 'thisQuarter':
                rangeStartDate = moment().startOf('quarter').format()
                rangeEndDate = moment().endOf('quarter').format()
                break
            case 'thisQuarterToDate':
                rangeStartDate = moment().startOf('quarter').format('L')
                rangeEndDate = currentDate
                break
            case 'thisYear':
                rangeStartDate = firstDayOfYear
                rangeEndDate = moment().endOf('year').format('L')
                break
            case 'thisYearToDate':
                rangeStartDate = firstDayOfYear
                rangeEndDate = currentDate
                break
            case 'thisYearToLastMonth':
                rangeStartDate = firstDayOfYear
                rangeEndDate = firstDayOfMonth
                break
            case 'yesterday':
                rangeStartDate = moment().subtract(1, 'days').format('L')
                rangeEndDate = rangeStartDate
                break
            case 'lastWeek':
                rangeStartDate = moment().subtract(1, 'week').weekday(0).format('L')
                rangeEndDate = moment().subtract(1, 'week').weekday(6).format('L')
                break
            case 'lastWeekToDate':
                rangeStartDate = moment().subtract(1, 'week').weekday(0).format('L')
                rangeEndDate = currentDate
                break
            case 'lastMonth':
                rangeStartDate = moment().subtract(1, 'month').startOf('month').format('L')
                rangeEndDate = moment().subtract(1, 'month').endOf('month').format('L')
                break
            case 'lastMonthToDate':
                rangeStartDate = moment().subtract(1, 'month').startOf('month').format('L')
                rangeEndDate = currentDate
                break
            case 'lastQuarter':
                rangeStartDate = moment().subtract(1, 'quarter').startOf('quarter').format('L')
                rangeEndDate = moment().subtract(1, 'quarter').endOf('quarter').format('L')
                break
            case 'lastQuarterToDate':
                rangeStartDate = moment().subtract(1, 'quarter').startOf('quarter').format('L')
                rangeEndDate = currentDate
                break
            case 'lastYear':
                rangeStartDate = moment().subtract(1, 'year').startOf('year').format('L')
                rangeEndDate = moment().subtract(1, 'year').endOf('year').format('L')
                break
            case 'lastYearToDate':
                rangeStartDate = moment().subtract(1, 'year').startOf('year').format('L')
                rangeEndDate = currentDate
                break
            case 'last7Days':
                rangeStartDate = moment().subtract(6, 'days').format('L')
                rangeEndDate = currentDate
                break
            case 'last30days':
                rangeStartDate = moment().subtract(30, 'days').format('L')
                rangeEndDate = currentDate
                break
            case 'last90days':
                rangeStartDate = moment().subtract(90, 'days').format('L')
                rangeEndDate = currentDate
                break
        }

        this.setFilter({
            rangeType: event,
            startDate: rangeStartDate,
            endDate: rangeEndDate,
            startDateCalendar: rangeStartDate,
            endDateCalendar: rangeEndDate
        })
    }

    setFilter(event) {
        this.onUpdate.emit(event)
    }


    ngOnDestroy() {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }
}
