import { ActivatedRoute } from '@angular/router'
import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, combineLatest, Subject } from 'rxjs'
import { tap, distinctUntilChanged, withLatestFrom, takeUntil, map, filter } from 'rxjs/operators'
import { Equipment } from '@app/shared/models/equipment.model'

import { Schedule } from '@app/shared/models/schedule.model'
import * as fromCoreStore from '@app/core/store'
import * as fromUserStore from '@app/users/store'


import { User } from '@app/shared/models'
import { SocketService, Candidate, getContentHTMLWithMention } from '@app/shared'
import * as fromSidebarStore from '@app/sidebar/store'
import moment from 'moment'
import { OpenModal } from '@app/modals/store'

@Component({
    selector: 'app-schedules',
    template: `
      <div class="schedules-container">
        <app-schedule-filter
          [filters]="filters"
          [types]="types"
          [statuses]="statuses"
          [total]="totalCount"
          [filtersWidthoutStatus]="filtersWidthoutStatus"
          [filtersWidthoutType]="filtersWidthoutType"
          [currentUser]="currentUser"
          (onUpdate)="setFilter($event)"
          (onClear)="clearFilter({
            subType: 'Drivers',
            allDispatcher: true,
            viewType: filters?.viewType,
          }); name = ''"
          (onCreate)="onCreate()"
        ></app-schedule-filter>

        @if (filters?.viewType == 'LIST') {
          <app-schedule-list
            [schedules]="schedules"
            [filters]="filters"
            [total]="totalCount"
            [equipments]="equipments"
            [currentUser]="currentUser"
            (onUpdate)="setFilter($event)"
            (onPage)="onPage($event)"
          ></app-schedule-list>
        } @else {
          <app-schedule-calendar
            [equipments]="equipments"
            [currentUser]="currentUser"
            [name]="name"
            (onUpdateName)="name = $event"
          ></app-schedule-calendar>
        }

      </div>
    `,
    styles: [
        `
        .schedules-container {
          padding: 20px;
        }
      `
    ],
    standalone: false
})
export class SchedulesComponent implements OnInit, OnDestroy {

  destroyed$ = new Subject<boolean>()
  LOCAL_STORAGE_KEY = 'CORE_SCHEDULES_CALENDAR'
  statuses = []
  types = []
  totalCount = 0
  loading = false
  name = ''

  filters: {
    status?: String,
    startDate?: Date,
    endDate?: Date,
    startDateCalendar?: Date,
    endDateCalendar?: Date,
    dateRange?: String,
    driver?: {
      _id: String,
      name: String,
    },
    dispatcher?: {
      _id: String,
      name: String,
    },
    unit?: {
      _id: String,
      name: String,
    },
    type?: String,
    sort?: String,
    page?: Number,
    limit?: Number,
    subType?: String,
    viewType?: String
    allDispatcher?: boolean
  }

  equipments = []

  filtersWidthoutStatus
  filtersWidthoutType
  currentUser: User

  schedules: Schedule[]

  constructor(
    private store: Store<fromCoreStore.State>,
    public _SocketService: SocketService,
    private cdr: ChangeDetectorRef
  ) { }

  allDispatcher = false

  ngOnInit() {
    combineLatest([
      this.store.select(fromCoreStore.selectScheduleFilter),
      this.store.select(fromUserStore.getLoggedIn),
      this.store.select(fromUserStore.getUser)
    ])
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(([
        filtersStore, 
        loggedIn,
        user
      ]) => {

        let filtersLS = JSON.parse(window.localStorage.getItem(this.LOCAL_STORAGE_KEY))

        let filters = {
          ...filtersLS,
          allDispatcher: filtersStore?.allDispatcher,
        }

        if (filtersStore?.sort && filtersStore?.dir) {
          filters = {
            ...filters,
            sort: filtersStore?.sort,
            dir: filtersStore?.dir,
          }
        } else {
          delete filters?.sort
          delete filters?.dir
        }

        this.allDispatcher = !!filters?.allDispatcher || false

        if (!filters?.viewType) {
          filters['viewType'] = 'LIST'
        }

        if (!filters?.subType) {
          filters['subType'] = 'Drivers'
        }

        if (filters?.viewType == 'LIST') {
          if (filters?.type == 'DAY OFF') {
            filters['type'] = 'DAY_OFF'
          }

          let f = {
            ...filters
          }

          if (f?.rangeType) {
            delete f.rangeType
          }

          if (user?.type == 'DISPATCH' && !this.allDispatcher && !filtersLS?.dispatcher) {
            f['dispatcher'] = {
              _id: user._id,
              name: user.name
            }

            this.filters = {
              ...this.filters,
              dispatcher: {
                _id: user._id,
                name: user.name
              }
            }
          }

          if (f?.driver) {
            f['driverId'] = f?.driver?._id
            delete f.driver
          }

          if (f?.unit) {
            f['unitId'] = f?.unit?._id
            delete f.unit
          }

          if (f?.dispatcher) {
            f['dispatchId'] = f?.dispatcher?._id
            delete f.dispatcher
          }
          delete f?.allDispatcher
          if (loggedIn && !filters?.onlySetStorage) {
            this.store.dispatch(new fromCoreStore.GetSchedules(f))
          }
        } else {
          if (!filters?.startDateCalendar) {
            filters['startDateCalendar'] = moment().utc().startOf('month').format('MM/DD/YYYY')
          }

          if (!filters?.endDateCalendar) {
            filters['endDateCalendar'] = moment().utc().endOf('month').format('MM/DD/YYYY')
          }

          let f = {
            ...filters
          }

          if (user?.type == 'DISPATCH' && !this.allDispatcher) {
            f['dispatchId'] = user._id

            this.filters = {
              ...this.filters,
              dispatcher: {
                _id: user._id,
                name: user.name
              }
            }
          }

          if (f['dispatcher']) {
            f['dispatchId'] = f['dispatcher']._id
          }

          delete f?.rangeType
          delete f?.dispatcher
          delete f?.driver
          delete f?.limit
          delete f?.sort
          delete f?.page
          delete f?.unit
          delete f?.dir

          f['viewType'] = 'CALENDAR'
          delete f?.allDispatcher
          if (loggedIn && !filters?.onlySetStorage) {
            this.store.dispatch(new fromCoreStore.GetCalendar(f))
          }
        }

        if (user?.type == 'DISPATCH' && !this.allDispatcher && !filtersLS?.dispatcher) {
          filters['dispatcher'] = {
            _id: user._id,
            name: user.name
          }
        }

        if (!filters?.subType) {
          filters['subType'] = 'Drivers'
        }

        if (filters?.type == 'DAY_OFF') {
          filters.type = 'DAY OFF'
        }

        this.filters = filters

        let filter = { ...this.filters }
        delete filter?.status
        delete filter?.subType
        this.filtersWidthoutStatus = filter

        let filterType = { ...this.filters }
        delete filterType?.subType
        delete filterType?.type
        delete filterType?.allDispatcher
        this.filtersWidthoutType = filterType
      })

    this.store.select(fromCoreStore.selectAllSchedules).pipe(
      takeUntil(this.destroyed$),
      tap(schedules => {
        if (schedules) {
          this.schedules = schedules
          this.loading = true
          this.cdr.detectChanges()
        }
      })
    ).subscribe()

    this.store.select(fromCoreStore.selectSchedulesTotalCount).pipe(
      takeUntil(this.destroyed$),
      tap(totalCount => {
        this.totalCount = totalCount
        this.cdr.detectChanges()
      })
    ).subscribe()

    this.store.select(fromCoreStore.selectSchedulesTabs).pipe(
      takeUntil(this.destroyed$),
      tap(tabs => {
        if (tabs) {
          this.statuses = tabs.statuses
          this.types = tabs.types
          this.cdr.detectChanges()
        }
      })
    ).subscribe()

    this.store
      .select(fromUserStore.getUser)
      .pipe(
        takeUntil(this.destroyed$),
        tap(user => {
          this.currentUser = user
        })
      )
      .subscribe()
  }

  setFilter(event) {
    this.filters = {
      ...event,
      page: 1
    }
    this.loading = true
    this.store.dispatch(new fromCoreStore.SetSchedulesFilter(this.filters))
  }

  onPage(event) {
    let filters = {
      ...this.filters,
      ...event
    }
    this.loading = true
    this.store.dispatch(new fromCoreStore.SetSchedulesFilter(filters))
  }

  onCreate() {
    this.store.dispatch(
      new OpenModal({
        type: 'VIEW_REQUEST',
        props: {
          windowClass: "view-request-modal",
          equipments: this.equipments,
          currentUser: this.currentUser,
          filters: this.filters,
          type: 'CREATE'
        },
      })
    )
  }

  clearFilter(event) {
    let filters = {
      ...this.filters,
      status: null,
      type: null,
      dispatcher: null,
      driver: null,
      startDate: null,
      endDate: null,
      startDateCalendar: null,
      endDateCalendar: null,
      ...event
    }
    this.store.dispatch(new fromCoreStore.SetSchedulesFilter(filters))
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}